import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    url = 'https://fsdfoodservice.karnavati.in/backend';
    consumerKey = 'ck_1674afadee0977a855408fb479307e3525a85336';
    consumerSecret = 'cs_d5b3897648e18853b9277b56c386c40025d61f34';
    constructor(
        private http: HttpClient,
        private toastr: ToastrService
    ) {

    }
    getAllProducts(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products?per_page=100&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }
    getAllHomeProducts(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products?per_page=100&category=16,18,17&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    getAllCategories(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products/categories?parent=0&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    getcategorydetail(id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products/categories/'+id+'?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }
    getcatdetail(id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products/categories/?slug='+id+'&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }


    getsubcategory(parent_cat_id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products/categories?parent='+parent_cat_id+'&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    getProductDetail(id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products/'+id+'?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    signIn(email, password){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        //headers.append('X-WP-Nonce','1016fa1acd');
        return this.http.post(this.url+'/wp-json/jwt-auth/v1/token?username='+email+'&password='+password,{headers:headers});
    }

    getnonce(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        //headers.append('X-WP-Nonce','1016fa1acd');
        return this.http.post(this.url+'/api/get_nonce/?json=get_nonce&controller=user&method=register',{headers:headers});
    }

    signUp(signupdata){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        //username,email,password,displayname,nonce
        //return this.http.post(this.url+'/api/user/register/?username='+username+'&email='+email+'&user_pass='+password+'&nonce='+nonce+'&display_name='+displayname ,{headers:headers});
        //return this.http.post(this.url+'/wp-json/wc/v3/customers?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,signupdata,{headers:headers});

        return this.http.post(this.url+'/wp-json/wc/v3/customers?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,signupdata,{headers:headers});
    }

    getcustomerdetail(customer_email){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/customers?email='+customer_email+'&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    getcustomerdetailbyid(id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/customers/'+id+'?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    updateaddress(id,address){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.post(this.url+'/wp-json/wc/v3/customers/'+id+'?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,address,{headers:headers});
    }

    updateprofile(id,profiledata){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.post(this.url+'/wp-json/wc/v3/customers/'+id+'?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,profiledata,{headers:headers});
    }

    getProductReviews(product_id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        //return this.http.get(this.url+'/wp-json/wc/v3/products/reviews?product='+product_id+'&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
        // return this.http.get(this.url+'/wp-json/proreview/v3/review/?product_id='+product_id,{headers:headers});
        return this.http.get(this.url+'/wp-json/wc/v3/products/reviews/?product_id='+product_id+'&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    savereview(product_id,review,username,useremail,rating){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.post(this.url+'/wp-json/wc/v3/products/reviews?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{product_id:product_id,review:review,reviewer:username,reviewer_email:useremail,rating:rating,comment_type:'comment'},{headers:headers});
    }

    getpages(slug){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wp/v2/pages?slug='+slug,{headers:headers});
    }

    getallpost(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wp/v2/posts?_embed&categories=57&per_page=20',{headers:headers});
    }

    getpostcategorywise(category_id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wp/v2/posts?_embed&categories='+category_id+'&per_page=20',{headers:headers});
    }



    getallrecipies(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wp/v2/posts?_embed&categories=60&per_page=20',{headers:headers});
    }

    getalltestimonial(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wp/v2/posts?_embed&categories=56&per_page=20',{headers:headers});
    }

    getnewsrooms(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wp/v2/posts?_embed&categories=59&per_page=20',{headers:headers});

    }

    getpostdetail(id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wp/v2/posts?_embed&include[]='+id,{headers:headers});
    }
    getblogdetail(slug){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wp/v2/posts?_embed&slug='+slug,{headers:headers});
    }


    getpagemedia(page_id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wp/v2/media?parent='+page_id,{headers:headers});
    }

    getProductAttributes(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products/attributes?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    getattributeTeams(attrid){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products/attributes/'+attrid+'/terms?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    productfilter(category_ids='',attr_filter_array = '',price_filter_str = '',sale_filter='',stock_status='',order_by_options=''){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        var category_filter='';
        if(category_ids != '' && typeof(category_ids)  !== "undefined"){
            category_filter = "category="+category_ids+'&';
        }
        return this.http.get(this.url+'/wp-json/wc/v3/products?'+category_filter+attr_filter_array+'&'+price_filter_str+'&'+sale_filter+'&'+stock_status+'&'+order_by_options+'&per_page=100&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }



    categoryproducts(category_id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products?per_page=100&status=publish&category='+category_id+'&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    saveorder(woocommercecart){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.post(this.url+'/wp-json/wc/v3/orders?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,woocommercecart,{headers:headers});
    }

    saveblogcomments(commentsdata){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.post(this.url+'/wp-json/wp/v2/comments',commentsdata,{headers:headers});
    }

    getblogcomments(id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wp/v2/comments?post='+id,{headers:headers});
    }

    getcustomerorders(customer_id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/orders?customer='+customer_id+'&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    getorderdetails(id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/orders/'+id+'?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    getcancelorder(id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.post(this.url+'/wp-json/wc/v3/orders/'+id+'?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{status : 'cancelled'},{headers:headers});
    }

    getattr_image(attrid){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/options/v1/opt/?option_name=z_taxonomy_image'+attrid+'&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    forgotpassword(email){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/api/forgot_password.php?login='+email,{headers:headers});
    }

    addproducttowishlist(data){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.post(this.url+'/wp-json/wishlist/v1/create',data,{headers:headers});
    }
    savePdf(data){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        
        const formData: FormData = new FormData();
        formData.append('resume', data);
        return this.http.post(this.url+'/api/careers.php', formData, {headers:headers});
        // return this.http.post(this.url+'/api/careers.php',data,{headers:headers});
    }

    savesquarebrand(data){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        // return this.http.get(this.url+'/wp-json/squarebrand/v1/register?name='+data.name+'&email='+data.email+'&phone='+data.phone+'&country='+data.country,{headers:headers});
        return this.http.get(this.url+'/api/register_brand.php?name='+data.name+'&email='+data.email+'&phone='+data.phone+'&country='+data.country,{headers:headers});
        // return this.http.post(this.url+'/api/register_brand.php',data,{headers:headers});
    }
    flipzin(data){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/api/flipzin-form.php?name='+data.name+'&email='+data.email+'&phone='+data.phone+'&country='+data.country,{headers:headers});
    }

    savecareer(data){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/api/careers.php?name='+data.name+'&email='+data.email+'&phone='+data.phone+'&address='+data.address+'&position_for='+data.position_for+'&workexp='+data.workexp+'&resume='+data.resume_name+'&action=send_resume',{headers:headers});
    }
    saveretail(data){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/api/retail-partner.php?business_name='+data.business_name+'&email='+data.email+'&phone='+data.phone+'&address='+data.address+'&contact_person='+data.contact_person+'&business_branches='+data.business_branches+'&business_city='+data.business_city+'&business_type='+data.business_type+'&information='+data.information+'&employees='+data.employees+'&local_sales='+data.local_sales+'&national_sales='+data.national_sales+'&regional_sales='+data.regional_sales+'&comments='+data.comments,{headers:headers});
    }
    savepartnership(data){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/api/retail-partner.php?business_name='+data.business_name+'&email='+data.email+'&phone='+data.phone+'&address='+data.address+'&contact_person='+data.contact_person+'&business_branches='+data.business_branches+'&business_city='+data.business_city+'&business_type='+data.business_type+'&information='+data.information+'&employees='+data.employees+'&local_sales='+data.local_sales+'&national_sales='+data.national_sales+'&regional_sales='+data.regional_sales+'&comments='+data.comments+'&local_number='+data.local_number+'&national_number='+data.national_number+'&regional_number='+data.regional_number,{headers:headers});
    }
    savedistributor(data){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/api/distributor.php?business_name='+data.business_name+'&email='+data.email+'&phone='+data.phone+'&address='+data.address+'&contact_person='+data.contact_person+'&business_branches='+data.business_branches+'&business_city='+data.business_city+'&business_type='+data.business_type+'&warehousing_property='+data.warehousing_property+'&logistics_property='+data.logistics_property+'&thirdpartyvendor_property='+data.thirdpartyvendor_property
            +'&rent_agreement='+data.rent_agreement+'&employees='+data.employees+'&local_sales='+data.local_sales+'&national_sales='+data.national_sales+'&regional_sales='+data.regional_sales,{headers:headers});
    }
    savequote(data){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/api/quote_req.php?company_name='+data.company_name+
        '&email='+data.email+
        '&phone='+data.phone+
        '&contact_person='+data.contact_person+
        '&fax='+data.fax+
        '&website='+data.website+
        '&closing_time='+data.closing_time+
        '&closing_date='+data.closing_date+
        '&product_name='+data.product_name+
        '&product_desc='+data.product_desc+
        '&attribute_name='+data.attribute_name+
        '&quantity='+data.quantity+
        '&reference='+data.reference+
        '&comments='+data.comments+
        '&delivery_address='+data.delivery_address+
        '&delivery_date='+data.delivery_date+
        '&delivery_time='+data.delivery_time,{headers:headers});
    }





    getwishlistproducts(id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wishlist/v1/getwishlistproducts/'+id,{headers:headers});
    }

    getwishlistproductinfo(prod_ids){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products?include='+prod_ids+'&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }

    removeproductfromwishlist(data){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.post(this.url+'/wp-json/wishlist/v1/remove',data,{headers:headers});
    }

    getcompanycoreproducts(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/coreproducts/v1/companycoreproducts',{headers:headers});
    }

    getpostsubcategories(parent_cat_id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wp/v2/categories?parent='+parent_cat_id,{headers:headers});
    }

    getvariationdetail(product_id,variation_id){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products/'+product_id+'/variations/'+variation_id+'?consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }
    searchProduct(search){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin','*');
        return this.http.get(this.url+'/wp-json/wc/v3/products?search='+search+'&consumer_key='+this.consumerKey+'&consumer_secret='+this.consumerSecret,{headers:headers});
    }


    showSuccess(msg:any) {
        this.toastr.success(msg,'');
    }
    showError(error:any) {
        this.toastr.error(error,'');
    }

}
