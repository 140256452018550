import { Injectable, PLATFORM_ID,Inject } from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { CartState } from 'src/app/cartState';
import { Product } from 'src/app/product';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CartService {

    private cartSubject = new Subject<CartState>();
    Products: Product[] = [];
    Products1: Product[] = [];
    CartState = this.cartSubject.asObservable();

    private myloginState = new Subject<any>();
    LoginState = this.myloginState.asObservable();

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        //localStorage.setItem('cart',JSON.stringify(this.cart));
    }

    public addProduct(_product: any) {
        if (isPlatformBrowser(this.platformId)) {
            this.Products = [];
            if (localStorage.getItem('cart')) {
                this.Products = JSON.parse(localStorage.getItem('cart'));
                let index = 0;
                for(let p=0;p<this.Products.length;p++){
                    if(_product.product_id === this.Products[p].product_id){
                        this.updateCart(this.Products[p],'add');
                        index = -1;
                        break;
                    }
                }
                if(index == 0){
                    this.Products.push(_product);
                }
                localStorage.setItem('cart', JSON.stringify(this.Products));

            }else{

                this.Products.push(_product);
                localStorage.setItem('cart', JSON.stringify(this.Products));
            }
            this.cartSubject.next(<CartState>{loaded: true, products:  JSON.parse(localStorage.getItem('cart')) });
            // update total
            this.updateFinalTotal();
            this.updateProductSub();
        }
    }

    public removeProduct(_product: any){

        this.Products = JSON.parse(localStorage.getItem('cart'));
        this.Products1 = Array();
        for (let i = 0; i < this.Products.length ; i++ ) {
            if (this.Products.length === 1) {

            } else {
                if (_product.product_id === this.Products[i].product_id) {

                } else {
                    this.Products1.push(this.Products[i]);
                }

            }
        }
        localStorage.setItem('cart', JSON.stringify(this.Products1));
        this.cartSubject.next(<CartState>{loaded: true, products:  JSON.parse(localStorage.getItem('cart')) });
        // update total
        this.updateFinalTotal();
        // update product subtotal
        this.updateProductSub();
    }

    public getProducts() {
        if (isPlatformBrowser(this.platformId)) {
            if(localStorage.getItem('cart')){
                return JSON.parse(localStorage.getItem('cart'));
            }else{
                return 0;
            }
            // update total
            this.updateFinalTotal();
            this.updateProductSub();
        }
    }

    public updateFinalTotal(){
        if (isPlatformBrowser(this.platformId)) {
            this.Products = JSON.parse(localStorage.getItem('cart'));
            var final_total = 0;
            for (let i = 0; i < this.Products.length; i++ ) {
                final_total = Number(this.Products[i].product_price * this.Products[i].product_qty) + final_total;
            }
            var total = { final_total : final_total }
            localStorage.setItem('final_total', JSON.stringify(total))
        }
    }

    public updateProductSub(){
        if (isPlatformBrowser(this.platformId)) {
            this.Products = JSON.parse(localStorage.getItem('cart'));
            for (let i = 0; i < this.Products.length; i++ ) {
                this.Products[i].product_subtotal = this.Products[i].product_price * this.Products[i].product_qty;
            }

            localStorage.setItem('cart', JSON.stringify(this.Products));
        }
    }

    public updateCart(_product: any, qtytype: any) {

        if (isPlatformBrowser(this.platformId)) {
            this.Products = [];
            this.Products1 = [];
            this.Products = JSON.parse(localStorage.getItem('cart'));
            for (let i = 0; i < this.Products.length ; i++ ) {

                if (_product.product_id === this.Products[i].product_id) {

                    if (qtytype === 'add') {
                        this.Products[i].product_qty = this.Products[i].product_qty + 1;
                    } else {
                        this.Products[i].product_qty = this.Products[i].product_qty - 1 ;
                    }
                    if(this.Products[i].product_qty > 0){
                        this.Products1.push(this.Products[i]);
                    }else{

                    }

                }else{
                    this.Products1.push(this.Products[i]);
                }

            }
            localStorage.setItem('cart', JSON.stringify(this.Products1));


            this.cartSubject.next(<CartState>{loaded: true, products:  JSON.parse(localStorage.getItem('cart')) });
            // update total
            this.updateFinalTotal();
            this.updateProductSub();
        }
    }

    emptyCart(){
        localStorage.removeItem('cart');
        this.cartSubject.next(<CartState>{loaded: true, products:  JSON.parse(localStorage.getItem('cart')) });

    }

    setLoginobserval(customer_token){
        this.myloginState.next({isLogin:true,customertoken:customer_token});
    }

}
