<header class="main-head">
	<div class="container-fluid">
		<div class="row align-items-center">
			<div class="col-lg-4 col-md-4 col-sm-12 col-12">
				<nav class="navbar navbar-expand-md navbar-light bg-light">

					<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarNavDropdown">
						<ul class="navbar-nav">
							<li class="nav-item active">
								<a class="nav-link" href="/">Home</a>
							</li>
							<li class="nav-item dropdown has-megamenu">
								<a class="nav-link dropdown-toggle" href="/page/company-overview" > About </a>
								<div class="dropdown-menu megamenu rounded-0 wd-dropdown border-0 p-4" role="menu">
									<div class="container">
										<div class="row">
											<div class="col-lg-6 col-md-6 col-sm-12 col-12">
												<div class="menu_options">
													<div class="vc_column-inner">
														<div class="wpb_wrapper">
															<ul class="wd-sub-menu mega-menu-list sub-menu">
																<li class="">
																	<a href="javascript:;" title="Shop page" class="position-relative new-pro-title">
																		<span class="nav-link-text">SERVICES</span>
																	</a>
																	<ul class="sub-sub-menu">
																		<li class="">
																			<a href="/page/retail-food-service-partnership" class="text-capitalize" title="">Retail & Foodservice Partnership</a>
																		</li>
																		<li class=" item-with-label item-label-primary">
																			<a href="/page/private-label-solutions" class="text-capitalize" title="">Private Label Solutions</a>
																		</li>
																		<li class="">
																			<a href="/page/strategic-brand-building-developments" class="text-capitalize" title="">
																				Strategic Brand Building & Developments
																			</a>
																		</li>
																		<li class="">
																			<a href="/page/sourcing-partnership" class="text-capitalize" title="">
																				Sourcing Partnership
																			</a>
																		</li>
																		<li class=" wd-display-none">
																			<a href="/page/distribution-solutions-services" class="text-capitalize" title="">
																				Distribution Solutions & Services
																			</a>
																		</li>
																	</ul>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											<div class="col-lg-6 col-md-6 col-sm-12 col-12">
												<div class="menu_options">
									                <div class="vc_column-inner">
									                    <div class="wpb_wrapper">
									                        <ul class="wd-sub-menu mega-menu-list sub-menu">
									                            <li class="">
									                                <a href="/page/company-overview" title="Shop page" class="position-relative new-pro-title">
									                                    <span class="nav-link-text">ABOUT US</span>
																	</a>
									                                <ul class="sub-sub-menu">
									                                    <li class="">
									                                        <a href="/page/company-overview" title="">Company Overview</a>
									                                    </li>
									                                    <li class=" item-with-label item-label-primary">
									                                        <a href="/page/company-information" title="">Company Information</a>
									                                    </li>
									                                    <li class="">
									                                        <a href="/page/company-policy" title="">
									                                            Company Policy
									                                        </a>
									                                    </li>
									                                    <li class="">
									                                        <a href="/page/privacy-policy" title="">
									                                            Privacy Policy
									                                        </a>
									                                    </li>
									                                    <li class=" wd-display-none">
									                                        <a href="/page/terms-of-use" title="">
									                                            Terms of Use
									                                        </a>
									                                    </li>
									                                    <li class="">
									                                        <a href="/page/return-policy" title="">
									                                            Return Policy
									                                        </a>
									                                    </li>
									                                    <li class="">
									                                        <a href="/page/our-locations" title="">
									                                           Our Locations
									                                        </a>
									                                    </li>
																	</ul>
									                            </li>
									                        </ul>
									                    </div>
									                </div>
									            </div>
											</div>
										</div>
									</div>
								</div>
							</li>

							<li class="nav-item dropdown has-megamenu">
								<a class="nav-link dropdown-toggle" href="/shop" > SHOP </a>
								<div class="dropdown-menu megamenu rounded-0 wd-dropdown border-0 p-4" role="menu">
									<div class="container">
										<div class="row">
											<div class="col-lg-3 col-md-3 col-sm-3 col-12">
												<div class="menu_options">
													<div class="vc_column-inner">
														<div class="wpb_wrapper">
															<ul class="wd-sub-menu mega-menu-list sub-menu">
																<li class="">
																	<a href="javascript:;" title="Shop page" class="position-relative new-pro-title">
																		<span class="nav-link-text">BRANDS</span>
																	</a>
																	<ul class="sub-sub-menu">
																		<li class="" *ngFor="let brand of brand_attributes">
																			<a href="/shop?pa_brand={{ brand.id }}&type=brand" title="">{{ brand.name }}</a>
																		</li>

																	</ul>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											<div class="col-lg-3 col-md-3 col-sm-3 col-12">
												<div class="menu_options">
													<div class="vc_column-inner">
														<div class="wpb_wrapper">
															<ul class="wd-sub-menu mega-menu-list sub-menu">
																<li class="">
																	<a href="javascript:;" title="Shop page" class="position-relative new-pro-title">
																		<span class="nav-link-text text-uppercase">Category list</span>

																	</a>
																	<ul class="sub-sub-menu">
																		<li class="" *ngFor="let c of all_category">
																			<a href="/shop?cat_id={{ c.id }}&type=category" title="">{{ c.name }}</a>
																		</li>

																	</ul>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											<div class="col-lg-3 col-md-3 col-sm-3 col-12">
												<div class="menu_options">
													<div class="vc_column-inner">
														<div class="wpb_wrapper">
															<ul class="wd-sub-menu mega-menu-list sub-menu">
																<li class="">
																	<a href="javascript:;" title="Shop page" class="position-relative new-pro-title">
																		<span class="nav-link-text">NEW PRODUCTS</span>

																	</a>
																	<ul class="sub-sub-menu">
																		<li class="" *ngFor="let n_product of new_products;">
																			<div class="blg_border">
																				<a href="/product/{{ n_product.id }}" class="d-flex text-decoration-none">
																					<div class="blog-img me-3">
																						<img src="{{ n_product.images[0].src }}" width="50" *ngIf="n_product.images.length > 0">
																						<img src="../assets/images/no-image.png" width="50" *ngIf="n_product.images.length == 0">
																					</div>
																					<div class="blog-text">
																						<p><span [innerHTML]=n_product.name></span><br>
																							<span class="price">$ {{ n_product.price }}</span>
																						</p>
																					</div>
																				</a>
																			</div>

																		</li>

																	</ul>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											<div class="col-lg-3 col-md-3 col-sm-3 col-12">
												<div class="menu_options">
													<div class="vc_column-inner">
														<div class="wpb_wrapper">
															<ul class="wd-sub-menu mega-menu-list sub-menu">
																<li class="">
																	<a href="javascript:;" title="Shop page" class="position-relative new-pro-title">
																		<span class="nav-link-text">LATEST PRODUCTS</span>
																	</a>
																	<ul class="sub-sub-menu">
																		<li class="" *ngFor="let l_product of latest_product;">
																			<div class="blg_border">
																				<a href="/product/{{ l_product.id }}" class="d-flex text-decoration-none">
																					<div class="blog-img me-3">
																						<img src="{{ l_product.images[0].src }}" width="50" *ngIf="l_product.images.length > 0">
																						<img src="../assets/images/no-image.png" width="50" *ngIf="l_product.images.length == 0">
																					</div>
																					<div class="blog-text">
																						<p><span [innerHTML]=l_product.name></span><br>
																							<span class="price">$ {{ l_product.price }}</span>
																						</p>
																					</div>
																				</a>
																			</div>

																		</li>

																	</ul>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- End -->
								</div>
							</li>

							<li class="nav-item dropdown has-megamenu">
								<a class="nav-link dropdown-toggle" href="/blogs" > Blog </a>
								<div class="dropdown-menu megamenu rounded-0 wd-dropdown border-0 p-4" role="menu">
									<div class="container">
										<div class="row">
											<div class="col-lg-12 col-md-12 col-sm-12 col-12">
												<div class="menu_options">
									                <div class="vc_column-inner">
									                    <div class="wpb_wrapper">
									                        <ul class="wd-sub-menu mega-menu-list sub-menu">
									                            <li class="">
									                                <a href="/blogs" title="Blog page" class="position-relative new-pro-title">
									                                    <span class="nav-link-text">Blogs</span>
																	</a>

									                            </li>
									                        </ul>
									                    </div>
									                </div>
									            </div>
											</div>
										</div>
										<div class="row blog_header rb-blog" *ngIf="available_posts">
											<div class="col-lg-6 col-md-6 col-sm-6 col-12 mb-3" *ngFor="let p of all_posts;let odd = odd;">
												<div class="blg_border">
													<a href="/blogs/{{ p.slug }}" class="d-flex text-decoration-none">
														<div class="blog-img">
															<img src="{{ p._embedded['wp:featuredmedia'][0].source_url }}" width="130">
														</div>
														<div class="blog-text">
															<p>{{ p.title.rendered }}<br>
																<span>{{ p.date | date:'mediumDate' }}</span>
															</p>
														</div>
													</a>
												</div>

											</div>
										</div>

										<div class="row" *ngIf="!available_posts">
											<div class="col-12">
												<h4>No blogs are available.</h4>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/contact">Contact</a>
							</li>
						</ul>
					</div>
				</nav>
			</div>


			<div class="col-lg-4 col-md-4 col-sm-8 col-8 text-center">
				<div class="main-logo">
					<a href="/">
						<img src="assets/images/fsd-logo.png" class="img-fluid desk-logo">
						<img src="assets/images/inner-logo.png" class="img-fluid mob-logo">
					</a>
				</div>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-4 col-4">
				<nav class="navbar navbar-expand-md navbar-light bg-light">
					<div class="collapse navbar-collapse justify-content-end pe-4" id="navbarNavDropdown">
						<ul class="navbar-nav cart">

<!-- 							<li class="nav-item dropdown">
								<a class="nav-link dropdown-toggle" href="javascript:;">EN</a>

							</li>

							<li class="nav-item dropdown">
								<a class="nav-link dropdown-toggle" href="javascript:;">USD</a>
							</li> -->

							<li class="nav-item" *ngIf="!isCustomerLogin">
								<a class="nav-link" href="/customer/login">Login / Register</a>
							</li>

							<li class="nav-item">
								<a class="nav-link" href="javascript:;"><i class="fas fa-search"></i></a>
							</li>

							<li class="nav-item">
								<a class="nav-link" href="javascript:;"><i class="far fa-heart"></i></a>
							</li>

							<li class="nav-item dropdown" *ngIf="isCustomerLogin">
								<div class="profile">
									<a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink"  aria-expanded="false">
										{{customer_name}}
									</a>
									<ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
										<li><a class="dropdown-item" href="/customer/edit-profile"><i class="fas fa-user-edit"></i> Edit Profile</a></li>
										<li><a class="dropdown-item" href="/customer/address-book"><i class="fas fa-address-book"></i>  Manage Address</a></li>
										<li><a class="dropdown-item" href="/customer/change-password"><i class="fas fa-key"></i> Change Password</a></li>
										<li><a class="dropdown-item" href="/customer/my-wishlist"><i class="fas fa-heart"></i> My Wishlist</a></li>
										<li><a class="dropdown-item" href="/customer/orders"><i class="fas fa-shipping-fast"></i> My Orders</a></li>
										<div class="dropdown-divider"></div>
										<li><a class="dropdown-item" href="javascript:;" (click)="logout()"><i class="fas fa-sign-out-alt"></i> Logout</a></li>
									</ul>
								</div>
							</li>

							<li class="nav-item position-relative">
								<a class="nav-link" href="javascript:;" (click)="ShowCartItems()">
									<i class="fas fa-shopping-cart"></i>
									<span  class="cartitem">{{(cart_item) ? cart_item : 0}}</span>
								</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="javascript:;" (click)="ShowCartItems()">$0.00</a>
							</li>
						</ul>
					</div>
				</nav>
				<div class="mobile-version-wrap">
						<div class="mobi_cart">
							<a class="nav-link" href="javascript:;" (click)="ShowCartItems()">
								<span class="position-relative">
									<i class="fas fa-shopping-cart"></i>
									<span  class="cartitem">{{(cart_item) ? cart_item : 0}}</span>
								</span>
							</a>
							<a class="nav-link" href="javascript:;" (click)="ShowCartItems()">${{ final_total }}</a>
						</div>


		                <button class="navbar-toggler float-end desktop-hide" type="button" (click)="toogleMenu()">
		                    <i [ngClass]="(openSidebar)?'fas fa-times':'fas fa-bars'" class="toggle-mobile"></i>
		                </button>
	            	</div>
				</div>
			</div>

		</div>
	<!-- </div> -->




</header>





<!-- Opan Cart  -->
<div id="navbarSide" class="navbar-side-new cart_slider" [class.reveal-cart] = "openCartbar == true">

<!-- <div id="navbarSide" class="navbar-side-new cart_slider row" [class.reveal-cart] = "openCartbar == true"> -->
	<div class="row">
		<div class="col-12">
			<div class="row mb-3 p-md-1">
				<div class="col-lg-6 col-md-6 col-sm-6 col-6">
					<a href="javascript:;" (click)="ShowCartItems()" class="cart_link text-decoration-none"><i class="fa fa-chevron-left me-2"></i><span> Keeping Shopping</span></a>
				</div>
				<div class="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
					<a href="cart" class="cart_link text-decoration-none"><span>Full Shopping Bag</span><i class="fa fa-chevron-right ms-2"></i></a>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center py-4 border-top border-bottom">
					<h2 class="shop">Shopping Bag</h2>
					<span class="total_item">( {{cart.length}} <span>Items</span> )</span>
				</div>
			</div>
		</div>


		<div class="col-12" *ngIf="!cartEmpty">
			<div class="row mb-3">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<ul class="cart_ul">
						<li *ngFor="let c of cart">
							<div class="cart_info">
								<div class="cart_image">
									<img alt="" src="{{c.product_image}}" class=""/>
								</div>
								<div class="cart_item_info ">

									<div class="item_name">{{ c.product_name }}</div>
									<div class="qty_section">
										<button type="button" (click)="decreaseQty(c)" name="button"><i class="fa fa-minus-circle"></i></button>
										<input type="text" readonly="" value="{{ c.product_qty}}" />
										<button (click)="increaseQty(c)" type="button" name="button"><i class="fa fa-plus-circle"></i></button>
									</div>
									<div class="remove_cart_item">
										<div class="price"><b>Price : </b>$ {{ c.product_price | number : '1.2-2' }}</div>
										<a (click)="removeProduct(c)" href="javascript:;"><i class="fa fa-trash-alt"></i></a>
									</div>
									<div class="remove_cart_item">
										<div class="price"><b><span>Subtotal</span> : </b>$ {{ c.product_subtotal | number : '1.2-2' }}</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12 py-3 border-top border-bottom">
					<div class="row px-5">
						<div class="col-lg-8 col-md-8 col-sm-6 col-6"><h2 class="shop">Subtotal :</h2></div>
						<div class="col-lg-4 col-md-4 col-sm-6 col-6 text-right"><span class="sub_total mr-md-2">$ {{ final_total | number : '1.2-2'  }}</span></div>
					</div>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12 py-4 text-center">
					<a href="cart" class="large_text text-dark text-decoration-none"><span>View Full Shopping Bag </span><i class="fa fa-chevron-right ms-2"></i></a>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-5 text-center">
					<div class="by-btn"><a href="/checkout" class="w-100">CHECKOUT</a></div>
				</div>
			</div>
		</div>
	</div>

	<div class="row" *ngIf="cartEmpty">
		<div class="col-lg-12 col-md-12 col-sm-12 col-12 py-md-5 text-center">
			<div class="empty_cart">
				<i class="fas fa-shopping-cart" style="font-size: 30px;"></i><br />
				<span class="w-100 my-5">No items are available in your cart.</span>
			</div>
			<div class="by-btn mt-5"><a (click)="ShowCartItems()" href="javascript:;">Keep Shopping</a></div>
		</div>
	</div>
</div>



<!-- mobile menu -->
<div class="navbar-side mobile-menu" id="navbarSide" [ngClass]="openSidebar ? 'reveal' : ''">
	<div class="close-sidebar-wrap">
		<a href="javascript:;" class="close-sidebar" (click)="toogleMenu()">
			<i class="fas fa-chevron-left"></i>
		</a>
	</div>
  <ul class="p-0">
    <li class="nav-item">
      <a class="nav-link" href="/">Home</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="javascript:void(0)" (click)="toggleSubMenu()">About Us <i class="fas fa-caret-down"></i></a>
      <div class="mb-drop sub-menu-wrap" *ngIf="openSubMenu">
      	<h2 class="mobile-menu-eading">Services</h2>
		<a  class="dropdown-item nav-link green" href="/page/retail-food-service-partnership">Retail & Foodservice Partnership</a>
		<a  class="dropdown-item nav-link green" href="/page/private-label-solutions">Private Label Solutions</a>
		<a  class="dropdown-item nav-link green" href="/page/strategic-brand-building-developments">Strategic Brand Building & Developments</a>
		<a  class="dropdown-item nav-link green" href="/page/sourcing-partnership">Sourcing Partnership</a>
		<a  class="dropdown-item nav-link green" href="/page/distribution-solutions-services">Distribution Solutions & Services</a>
      	
      	<h2 class="mobile-menu-eading">About Us</h2>
		<a href="/page/company-overview" class="dropdown-item nav-link green" title="">Company Overview</a>
		<a href="/page/company-information" class="dropdown-item nav-link green" title="">Company Information</a>
		<a href="/page/company-policy" class="dropdown-item nav-link green" title=""> Company Policy </a>
		<a href="/page/privacy-policy" class="dropdown-item nav-link green" title=""> Privacy Policy </a>
		<a href="/page/terms-of-use" class="dropdown-item nav-link green" title=""> Terms of Use </a>
		<a href="/page/return-policy" class="dropdown-item nav-link green" title=""> Return Policy </a>
		<a href="/page/our-locations" class="dropdown-item nav-link green" title=""> Our Locations </a>

      </div>
    </li>
    <!-- <li class="nav-item dropdown" id="navbarDropdown" role="button" data-toggle="dropdown"> -->
    <li class="nav-item">
      <a class="nav-link">Shop</a>
      <!-- <div class="mb-drop" *ngIf="openSubMenu">
        <a  class="dropdown-item nav-link green" href="/product/category/" (click)="toogleMenu()">Test 1</a>
      </div> -->
    </li>
    <li class="nav-item">
      <a class="nav-link" href="/blogs">Blog</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="/contact">Contact</a>
    </li>
    <li class="nav-item" *ngIf="!isCustomerLogin">
		<a class="nav-link" href="/customer/login">Login / Register</a>
	</li>
	<li class="nav-item dropdown" *ngIf="isCustomerLogin">
		<div class="profile">
			<a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" id="dropdownMenuLink" (click)="toggleUserMenu()" aria-expanded="false">Welcome, {{customer_name}}</a>
			<ul class="user-dropdown-menu" aria-labelledby="dropdownMenuLink" *ngIf="openUserMenu">
				<li><a class="dropdown-item" href="/customer/edit-profile"><i class="fas fa-user-edit"></i> Edit Profile</a></li>
				<li><a class="dropdown-item" href="/customer/address-book"><i class="fas fa-address-book"></i>  Address Book</a></li>
				<li><a class="dropdown-item" href="/customer/change-password"><i class="fas fa-key"></i> Change Password</a></li>
				<li><a class="dropdown-item" href="/customer/my-wishlist"><i class="fas fa-heart"></i> My Wishlist</a></li>
				<li><a class="dropdown-item" href="/customer/orders"><i class="fas fa-shipping-fast"></i> My Orders</a></li>
				<div class="dropdown-divider"></div>
				<li><a class="dropdown-item" href="javascript:;" (click)="logout()"><i class="fas fa-sign-out-alt"></i> Logout</a></li>
			</ul>
		</div>
	</li>


  </ul>
  <!-- <a href="javascript:;" class="close-sidebar pull-right" (click)="toogleMenu()">
    <i class="fas fa-chevron-left"></i>
  </a> -->
</div>
<!-- Sticky Menu Web -->
