import { Component, OnInit,PLATFORM_ID,Inject } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { CartService } from 'src/app/cart.service';
import { CartState } from 'src/app/cartState';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-innerheader',
  templateUrl: './innerheader.component.html',
  styleUrls: ['./innerheader.component.css']
})

export class InnerheaderComponent implements OnInit {

    openSidebar : boolean = false;
    openSubMenu : boolean = false;
    openUserMenu : boolean = false;

    searchOpen : boolean = false;
    ProductSwap : boolean = false;
    customer_name:any;
    isCustomerLogin : boolean = false;
    returnUrl : any;
    openCartbar : boolean = false;
    availablesettings : boolean = false;

    cartEmpty : boolean = true;
    cart : any;
    Settings : any;
    subtotal : any;
    final_total : any = '0.00';
    subscription: Subscription;

    cart_item : any;
    products: any;

    all_posts : any;
    available_posts : boolean = false;

    brand_attributes : any;
    available_attribute: boolean = false;

    all_category : any;
    available_category : boolean = false;

    available_new_products: boolean = false;
    new_products : any;

    all_products : any;
    available_all_products : boolean = false;

    available_latest_products: boolean = false;
    latest_product : any;

  constructor(public customservice:ApiService,public router : Router,public cartservice:CartService,@Inject(PLATFORM_ID) private platformId : Object) {
     if(isPlatformBrowser(this.platformId)){

            this.subscription = this.cartservice.CartState.subscribe((state: CartState) => {
                this.products = state.products;
                if(this.products){
                    let cart_prod =  this.cartservice.getProducts();
                    this.cart_item = cart_prod.length;
                    if(cart_prod.length > 0){
                        this.final_total = cart_prod.map(item => item.product_subtotal).reduce((prev, next) => prev + next);
                    }else{
                        this.final_total =0;
                    }
                }else{
                    this.cart_item = 0;
                }
            });

            this.cartservice.LoginState.subscribe(
                (lstate:any)=>{
                    if(lstate.isLogin){
                        this.customer_name = lstate.user_display_name;
                        this.isCustomerLogin = true;
                    }
                }
            );
        }
        this.getallcategory();
        this.getproducts();
        this.getallpost();
        this.getbrandattributes();
        this.getCatdata();

   }

  ngOnInit(): void {
        if(localStorage.getItem('customerinfo')){
            let customer_data = JSON.parse(localStorage.getItem('customerinfo'));
            this.customer_name = customer_data.user_display_name;
            this.isCustomerLogin = true;
        }
  }




    getCatdata(){
        this.cart = this.cartservice.getProducts();
        this.cart_item = this.cart.length;
        if(this.cart != 0){
            this.subtotal = this.final_total = this.cart.map(item => item.product_subtotal).reduce((prev, next) => prev + next);;
            this.cartEmpty = false;
        }else{
            this.final_total = 0;
            localStorage.removeItem('quoteID');
            this.cartEmpty = true;
        }
    }

    getallpost(){
        this.customservice.getallpost().subscribe(
            (data:any)=>{
                if(data.length > 0){
                    this.all_posts = data;
                    this.available_posts = true;
                }

            },(errors:any)=>{

            }
        );
    }

    removeProduct(product){
        this.cartservice.removeProduct(product);
        this.getCatdata();
    }

    decreaseQty(product){
        this.cartservice.updateCart(product,'sub');
        this.getCatdata();
    }

    increaseQty(product){
        this.cartservice.updateCart(product,'add');
        this.getCatdata();
    }

    ShowCartItems(){
        this.getCatdata();
        if(this.openCartbar) {
            this.openCartbar = false;
        }else {
            this.openCartbar = true;
        }
    }

    getallcategory(){
        this.customservice.getAllCategories().subscribe(
            (data:any)=>{
                if(data.length > 0){
                    let allcategory = data;
                    // sort category assending orders
                    allcategory.sort(function(a, b) {
                        return a.menu_order - b.menu_order;
                    });
                    // end
                    this.all_category = allcategory;
                    this.available_category = true;
                }
            },(errors:any)=>{

            }
        )
    }

    getbrandattributes(){
        this.customservice.getattributeTeams('3').subscribe(
            (data:any)=>{
                // sort category assending orders
                data.sort(function(a, b) {
                    return a.id - b.id;
                });
                // end
                this.brand_attributes = data;
                this.available_attribute = true;

            },(errors:any)=>{

            }
        );
    }

    getproducts(){
        this.customservice.getAllProducts().subscribe(
            (data:any)=>{
                let fsd_data = [];
                this.all_products = data;
                this.getnewandlatestproducts(this.all_products);
                let all_products = this.all_products;
                if(this.all_products.length > 0){
                    this.available_all_products = true;
                }else{
                    this.available_all_products = false;
                }



            },(errors:any)=>{

            }
        )
    }

    getnewandlatestproducts(products){
        const shuffled = products.sort(() => 0.5 - Math.random());
        let selected = shuffled.slice(0, 5);

        this.new_products = selected;
        if(selected.length > 0){
            this.available_new_products = true;
        }else{
            this.available_new_products = false;
        }

        products.sort( function ( a, b ) { return b.id - a.id; } );
        let latest_product = products.slice(0,5);
        //
        this.latest_product = latest_product;
        if(latest_product.length > 0){
            this.available_latest_products = true;
        }else{
            this.available_latest_products = false;
        }
    }

    logout(){
        localStorage.removeItem('customerinfo');
        window.location.href="/";
    }
    toggleSearch(){
        if (this.searchOpen) {
            this.searchOpen = false;
        }else{
            this.searchOpen = true;
        }
    }
    toogleMenu(){
        if(this.openSidebar) {
          this.openSidebar = false;
        }else {
          this.openSidebar = true;
        }
    }
    toggleSubMenu(){
        if(this.openSubMenu) {
          this.openSubMenu = false;
        }else {
          this.openSubMenu = true;
        }
    }
    toggleUserMenu(){
        if(this.openUserMenu) {
          this.openUserMenu = false;
        }else {
          this.openUserMenu = true;
        }
    }

}
