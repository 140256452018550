import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removebr'
})
export class RemovebrPipe implements PipeTransform {

    transform(value: any): string {
        return value.replace("<br>", " ");
    }

}
