import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    available_attribute :boolean = false;
    brand_attributes : any;

    available_posts : boolean = false;
    all_posts : any;

    constructor(
        public api : ApiService
    ) {
        this.getbrandattributes();
        this.getallpost();
    }

    ngOnInit(): void {

    }

    getbrandattributes(){
        this.api.getattributeTeams('3').subscribe(
            (data:any)=>{

                // sort category assending orders
                data.sort(function(a, b) {
                    return a.id - b.id;
                });
                // end
                this.brand_attributes = data;
                this.available_attribute = true;

            },(errors:any)=>{

            }
        );
    }

    getallpost(){
        this.api.getallpost().subscribe(
            (data:any)=>{
                let post = data.slice(0, 3) ;
                if(post.length > 0){
                    this.all_posts = post;
                    this.available_posts = true;
                }

            },(errors:any)=>{

            }
        );
    }

}
