<div class="quickview_product p-4">
    <div class="close">
        <a href="javascript:;" (click)="close()" class="text-dark text-decoration-none"><i class="fas fa-times-circle"></i></a>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="available_product_detail">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <ng-container *ngIf="available_product_images">
                    <owl-carousel-o [options]="ProductImageOptions" class="Mainbanner">
                        <ng-template carouselSlide *ngFor="let i of product_images;">
                            <div class="main-slider">
                                <img src="{{ i.src }}" class="w-100">
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </ng-container>

                <ng-container *ngIf="!available_product_images">
                    <img src="{{ no_image }}" alt="" class="w-100 img-fluid">
                </ng-container>

            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="product_name" [innerHTML]="product_detail.name"></div>

                <div class="product_price">
                    <span *ngIf="product_regular_price">${{ product_regular_price }}</span> ${{ product_sale_price }}
                </div>


                <ng-container *ngIf="product_detail.attributes.length > 0">
                    <ng-container *ngFor="let a of product_detail.attributes">
                        <div class="attribute_section" *ngIf="a.id != 3">
                            <label><strong>{{ a.name }}</strong></label>
                            <ul>
                                <li *ngFor="let o of a.options"><a href="javascript:;" [class.active_varient]="activated_varient_lable == o" (click)="select_variation(a.id,o)">{{ o }}</a></li>
                            </ul>
                        </div>
                    </ng-container>

                </ng-container>

                <div class="product_desc" [innerHTML]="product_detail.short_description"></div>

                <div class="cat">
                    <p>
                        <span>Category:</span> {{ product_detail.categories[0].name }}<br>

                        <span>Tags:</span> {{ (product_detail.tags.length > 0) ? product_detail.tags[0].name : '--' }}<br>
                    </p>

                </div>

                <div class="cart-btn">

                    <div class="count-img quantity buttons_added">
                        <input type="button" value="-" class="minus" (click)="quntity('0')">
                        <input type="number" step="1" min="1" max="" name="quantity" value="{{ product_qty }}" title="Qty" class="input-text qty text" size="4" pattern="" inputmode="">
                        <input type="button" value="+" class="plus" (click)="quntity('1')">
                    </div>

                    <div class="add-to-cart-btn">
                        <div  id="button-3" class="button">
                            <div id="circle"></div>
                            <a  href="javascript:;" (click)="Addtocart()" class="view-btn">Add to cart</a>
                        </div>
                    </div>
                </div>

                <div class="main-comper">
                    <div class="comper">
                        <a href="javascript:;" (click)="addtowishlist()" class="d-flex align-items-center text-decoration-none text-dark">
                            <img src="../assets/images/wishlist.png">
                            <p>Add to wishlist</p>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center p-5 my-5 loading" *ngIf="!available_product_detail">
        <h2>Loading......</h2>
    </div>
</div>
