import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './api.service';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CartService } from './cart.service';
import { ToastrModule } from 'ngx-toastr';
import { RemovebrPipe } from './removebr.pipe';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ProductQuickViewComponent } from './product-quick-view/product-quick-view.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        RemovebrPipe,
        ProductQuickViewComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        CarouselModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        ShareButtonsModule,
        ShareButtonsPopupModule,
        ShareIconsModule,
        NgbModule

    ],
    providers: [
        ApiService,
        CartService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
