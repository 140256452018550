<app-header></app-header>
<section class="home-banner">
    <owl-carousel-o [options]="MainBannerOptions" class="Mainbanner">

        <ng-template carouselSlide>
            <div class="main-slider">
                <a href="/shop">
                    <img src="assets/images/banner-111.png" class="w-100">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-slider">
                <a href="/shop?cat_id=16&type=category">
                    <img src="assets/images/banner-222.png" class="w-100">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-slider">
                <a href="/shop?cat_id=17&type=category">
                    <img src="assets/images/banner-333.png" class="w-100">
                </a>
            </div>
        </ng-template>

        <!-- <ng-template carouselSlide>
            <div class="main-slider">
                <img src="assets/images/banner1.jpg" class="w-100">
                <img src="assets/images/banner-11.jpg" class="w-100">
            </div>
            <div class="slider-text">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <h1>Build<br>
                                A Perfect<br>
                                Flavour Into<br>
                                Every Bite
                            </h1>
                            <div class="slid-btn">
                                <div class="button shp-now" id="button-3">
                                    <div id="circle"></div>
                                    <a href="/shop">Shop Now</a>
                                </div>
                                <div class="button" id="button-3">
                                    <div id="circle"></div>
                                    <a href="/shop" class="view-btn">View More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template> -->

        <!-- <ng-template carouselSlide>
            <div class="main-slider">
                <img src="assets/images/banner-2.jpg" class="w-100">
            </div>
            <div class="slider-text">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <h1>Partner<br> Products</h1>
                            <div class="slid-btn">
                                <div class="button shp-now" id="button-3">
                                    <div id="circle"></div>
                                    <a href="/shop">Shop Now</a>
                                </div>
                                <div class="button" id="button-3">
                                    <div id="circle"></div>
                                    <a href="/shop" class="view-btn">View More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template> -->

        <!-- <ng-template carouselSlide>
            <div class="main-slider">
                <img src="assets/images/banner-4.jpg" class="w-100">
            </div>

        </ng-template> -->

        <!-- <ng-template carouselSlide>
            <div class="main-slider">
                <img src="assets/images/banner-5.jpg" class="w-100">
            </div>

        </ng-template> -->

        <!-- <ng-template carouselSlide>
            <div class="main-slider">
                <img src="assets/images/banner-6.jpg" class="w-100">
            </div>

        </ng-template>

        <ng-template carouselSlide>
            <div class="main-slider">
                <img src="assets/images/banner-2-main.jpg" class="w-100">
            </div>

        </ng-template> -->

        <!-- <ng-template carouselSlide>
            <div class="main-slider">
                <img src="assets/images/banner-8.jpg" class="w-100">
            </div>

        </ng-template> -->


    </owl-carousel-o>

</section>

<section class="slider-bottom">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                <div class="donvictor-text pick">
                    <a href="/category-products/breading" class="text-decoration-none">
                        <div class="hot-sous-text-inn pick">
                            <div class="het-bg">
                                <h3>Breading</h3>
                                <div class="zoom-section">
                                    <img src="assets/images/breading.png" class="w-75 img-fluid">
                                </div>

                            </div>
                        </div>
                    </a>

                </div>
                <div class="donvictor-text granul">
                    <a href="/category-products/spices" class="text-decoration-none">
                        <div class="hot-sous-text-inn granul">
                            <div class="het-bg">
                                <h3>
                                    Spices
                                </h3>
                                <div class="hot-sous-img zoom-section">
                                    <img src="assets/images/Spices-new.png" class="w-75 img-fluid">
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="donvictor-text inn">
                    <a href="/category-products/beverages" class="text-decoration-none">
                        <div class="hot-sous-text-inn">
                            <h3>Beverages</h3>
                            <p>Build a perfect flavour into every bite.</p>
                        </div>
                        <div class="hot-sous-img zoom-section">
                            <img src="assets/images/Beverages-1-new.png" class="w-100 img-fluid px-5 pt-5">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                <!-- <div class="donvictor-text pick">
                    <a href="/category-products/55" class="text-decoration-none">
                        <div class="hot-sous-text-inn pick">
                            <h3>Sauces</h3>
                            <div class="zoom-section">
                                <img src="assets/images/sause.png">
                            </div>
                        </div>
                    </a>
                </div> -->
                <div class="donvictor-text granul mt-0 full-height">
                    <a href="/category-products/partner-products" class="text-decoration-none">
                        <div class="hot-sous-text-inn granul">
                            <div class="het-bg">
                                <h3>
                                    Partner<br>Products
                                </h3>
                                <div class="hot-sous-img yad zoom-section">
                                    <img src="assets/images/Partner-Products-new.png" class="w-75 img-fluid">
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="fsd-sec">
    <div class="container">
        <div class="contant-pagtitle">
            <h2>FSD Featured Products</h2>
            <img src="assets/images/title-img.png">
            <p>Our products are made from the highest quality ingredients.</p>
        </div>
        <div class="row"></div>
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" *ngIf="available_category">
            <li *ngFor="let c of all_category;" class="nav-item" role="presentation" (click)="change_category(c.slug)">
                <button class="nav-link custom_nav" type="button">
                    <img *ngIf="c.image" src="{{ c.image.src }}" /><br>
                    {{ c.name }}
                </button>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="row" *ngIf="available_fsd_products">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-md-4 mb-2" *ngFor="let product of fsd_fetured_products;let o=index;">
                        <div *ngIf="(o+1) <=8"  class="main-fither product-grid-item product product-no-swatches wd-hover-base woodmart-hover-base first type-product post-15615 status-publish instock product_cat-organic has-post-thumbnail shipping-taxable purchasable product-type-variable hover-ready">
                            <div class="product-wrapper">
                                <div class="content-product-imagin" style="margin-bottom: -146px;"></div>
                                <div class="product-element-top">
                                    <!-- <a href="/product/{{ product.id }}" class="product-image-link agve-img">
                                    <img src="{{ product.images[0].src }}" class="w-100 attachment-woocommerce_thumbnail size-woocommerce_thumbnail">
                                </a> -->

                                <a href="/product/{{ product.id }}" class="product-image-link agve-img">
                                    <div class="product_bg_image" [style.background-image]="'url('+ product.images[0].src +')'" *ngIf="product.images.length > 0"></div>
                                    <div class="product_bg_image" [style.background-image]="'url(../assets/images/no-image.png)'" *ngIf="product.images.length == 0"></div>
                                </a>

                                <div class="wrapp-swatches">
                                    <div class="wd-compare-btn product-compare-button wd-action-btn wd-style-icon wd-compare-icon">
                                        <a  href="javascript:;" class="text-decoration-none" (click)="quickview(product.id)"><i class="fa fa-random"></i></a>
                                    </div>
                                </div>

                                <div class="quick-shop-wrapper wd-fill wd-scroll">
                                    <div class="quick-shop-close wd-action-btn wd-style-text wd-cross-icon"><a href="#" rel="nofollow noopener">Close</a></div>
                                    <div class="quick-shop-form wd-scroll-content"></div>
                                </div>

                            </div>

                            <div class="product-information">
                                <h3 class="wd-entities-title"><a href="/product/{{ product.id }}" class="text-decoration-none chip"><p [innerHTML]="product.name | removebr"></p></a></h3>
                                <div class="wd-product-cats">
                                    <a href="/product/{{ product.id }}" class="text-decoration-none" rel="tag">{{ product.categories[0].name }}</a>
                                </div>
                                <div class="product-rating-price">
                                    <div class="wrapp-product-price">
                                        <span class="price">
                                            <span class="woocommerce-Price-amount amount">
                                                <bdi>
                                                    <span class="woocommerce-Price-currencySymbol">$</span>{{ product.price }}

                                                </bdi>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="fade-in-block">
                                    <div class="hover-content wd-more-desc woodmart-more-desc wd-more-desc-calculated">
                                        <div class="hover-content-inner wd-more-desc-inner woodmart-more-desc-inner" [innerHTML]="(product.short_description) ? (product.short_description | slice:0:70) + '...' : ''"></div>
                                        <a href="#" rel="nofollow" class="wd-more-desc-btn woodmart-more-desc-btn wd-shown"><span></span></a>
                                    </div>
                                    <div class=" wd-bottom-actions">
                                        <div class="wrap-wishlist-button">
                                            <div class="wd-wishlist-btn wd-action-btn wd-style-icon wd-wishlist-icon woodmart-wishlist-btn">
                                                <a class="text-decoration-none" href="javascript:;" (click)="addtowishlist(product.id)"><i class="far fa-heart"></i></a>
                                            </div>
                                        </div>
                                        <div class="wd-add-btn wd-add-btn-replace woodmart-add-btn">
                                            <a *ngIf="product.type == 'simple'"  (click)="Addtocart(product.id,product.name,product.images[0],product.price)" href="javascript:;" data-quantity="1" class="button product_type_variable add_to_cart_button add-to-cart-loop" data-product_sku="" rel="nofollow"><span>Add to Cart</span></a>
                                            <a *ngIf="product.type == 'variable'" href="/product/{{ product.id }}" class="button product_type_variable add_to_cart_button add-to-cart-loop" rel="nofollow"><span>Select Options</span></a>
                                            <!-- <a *ngIf="product.type == 'simple'" (click)="Addtocart(product.id,product.name,product.images[0],product.price)" href="javascript:;" data-quantity="1" class="button product_type_variable add_to_cart_button add-to-cart-loop" data-product_sku=""  rel="nofollow"><span>Add to Cart</span></a> -->
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-md-3 col-sm-12 col-12" *ngFor="let product of fsd_fetured_products;">
                <div class="main-fither">
                <a href="/product/{{ product.id }}">
                <div class="fecherpro">
                <img src="{{ product.images[0].src }}" class="w-100">
                <div class="chip fsd_prod_name">
                <p [innerHTML]="product.name"></p>
                <span>{{ product.categories[0].name }}</span>
                <h4>${{ product.price }}</h4>
            </div>
        </div>
    </a>
</div>
</div> -->
</div>

<div class="row" *ngIf="!available_fsd_products">
    <div class="col-12 my-5 text-center">
        <h4>No products are available.</h4>
    </div>
</div>
</div>
</div>
</div>
</section>



<section class="primeum">
    <div class="container">
        <div class="contant-pagtitle">
            <h2>Premium Product</h2>
            <img src="assets/images/title-img.png">
            <p>Proven Quality and Outstanding Flavors.</p>
        </div>
        <div class="main-pri" *ngIf="available_premium_products">
            <owl-carousel-o [options]="PremiumOptions">
                <ng-template carouselSlide *ngFor="let premium of premium_products">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="prim-left-img">
                                <img src="{{ premium.images[0].src }}" *ngIf="premium.images.length > 0">
                                <img src="../assets/images/no-image.png" *ngIf="premium.images.length == 0">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="agva-text">
                                <h3 [innerHTML]="premium.name"></h3>
                                <div class="short_desc" [innerHTML]="premium.short_description"></div>
                            </div>
                            <div class="fruit-main">
                                <div class="fruits">
                                    <h4>Fruits:</h4>
                                    <p>{{ premium.meta_data[4].value }}</p>
                                </div>
                                <div class="fruits">
                                    <h4>Ingredients:</h4>
                                    <p>{{ premium.meta_data[6].value }}</p>
                                </div>
                            </div>
                            <div class="fruit-main bottom">
                                <div class="fruits">
                                    <h4>SHELF LIFE:</h4>
                                    <p>{{ premium.meta_data[2].value }}</p>
                                </div>
                                <div class="fruits">
                                    <h4>BOTTLE SIZE:</h4>
                                    <p>{{ premium.attributes[0].options[0] }}</p>
                                </div>
                            </div>

                            <div class="agve">
                                <div class="button" id="button-3">
                                    <div id="circle"></div>
                                    <a href="/product/{{ premium.id }}" class="view-btn">View More</a>
                                </div>

                                <div class="toshop">
                                    <div  id="button-3" class="button">
                                        <div  id="circle"></div>
                                        <a  href="/shop" class="view-btn">Go To Shop</a></div>
                                    </div>



                                </div>

                            </div>
                        </div>
                    </ng-template>


                </owl-carousel-o>

            </div>
        </div>
    </section>
    <section class="ourproduct">
        <div class="container">
            <div class="contant-pagtitle">
                <h2>Our Products</h2>
                <img src="assets/images/title-img.png">
                <p>Discover our range of products.</p>
            </div>
            <div class="row mt-5" *ngIf="available_all_products">
                <!-- Test New Code -->
                <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-md-4 mb-2" *ngFor="let product of all_products;let o=index;">
                    <div *ngIf="(o+1) <=8" class="mb-3 product-grid-item product product-no-swatches wd-hover-base woodmart-hover-base first type-product post-15615 status-publish instock product_cat-organic has-post-thumbnail shipping-taxable purchasable product-type-variable hover-ready">
                        <div class="product-wrapper">
                            <div class="content-product-imagin" style="margin-bottom: -146px;"></div>
                            <div class="product-element-top">
                                <!-- <a href="/product/{{ product.id }}" class="product-image-link agve-img">
                                <img src="{{ product.images[0].src }}" class="w-100 attachment-woocommerce_thumbnail size-woocommerce_thumbnail">
                            </a> -->

                            <a href="/product/{{ product.id }}" class="product-image-link agve-img">
                                <div class="product_bg_image" [style.background-image]="'url('+ product.images[0].src +')'" *ngIf="product.images.length > 0"></div>
                                <div class="product_bg_image" [style.background-image]="'url(../assets/images/no-image.png)'" *ngIf="product.images.length == 0"></div>
                            </a>
                            <div class="wrapp-swatches">
                                <div class="wd-compare-btn product-compare-button wd-action-btn wd-style-icon wd-compare-icon">
                                    <a  href="javascript:;" class="text-decoration-none" (click)="quickview(product.id)"><i class="fa fa-random"></i></a>
                                </div>
                            </div>

                            <div class="quick-shop-wrapper wd-fill wd-scroll">
                                <div class="quick-shop-close wd-action-btn wd-style-text wd-cross-icon"><a href="#" rel="nofollow noopener">Close</a></div>
                                <div class="quick-shop-form wd-scroll-content"></div>
                            </div>

                        </div>

                        <div class="product-information">
                            <h3 class="wd-entities-title"><a href="/product/{{ product.id }}" class="text-decoration-none chip"><p [innerHTML]="product.name | removebr"></p></a></h3>
                            <div class="wd-product-cats">
                                <a href="/product/{{ product.id }}" class="text-decoration-none" rel="tag">{{ product.categories[0].name }}</a>
                            </div>
                            <div class="product-rating-price">
                                <div class="wrapp-product-price">
                                    <span class="price">
                                        <span class="woocommerce-Price-amount amount">
                                            <bdi>
                                                <span class="woocommerce-Price-currencySymbol">$</span>{{ product.price }}

                                            </bdi>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div class="fade-in-block">
                                <div class="hover-content wd-more-desc woodmart-more-desc wd-more-desc-calculated">
                                    <div class="hover-content-inner wd-more-desc-inner woodmart-more-desc-inner" [innerHTML]="(product.short_description) ? (product.short_description | slice:0:70) + '...' : ''"></div>
                                    <a href="#" rel="nofollow" class="wd-more-desc-btn woodmart-more-desc-btn wd-shown"><span></span></a>
                                </div>
                                <div class=" wd-bottom-actions">
                                    <div class="wrap-wishlist-button">
                                        <div class="wd-wishlist-btn wd-action-btn wd-style-icon wd-wishlist-icon woodmart-wishlist-btn">
                                            <a class="text-decoration-none" href="javascript:;" (click)="addtowishlist(product.id)"><i class="far fa-heart"></i></a>
                                        </div>
                                    </div>
                                    <div class="wd-add-btn wd-add-btn-replace woodmart-add-btn">
                                        <a *ngIf="product.type == 'variable'" href="/product/{{ product.id }}" class="button product_type_variable add_to_cart_button add-to-cart-loop" rel="nofollow"><span>Select Options</span></a>
                                        <a *ngIf="product.type == 'simple'"  (click)="Addtocart(product.id,product.name,product.images[0],product.price)" href="javascript:;" data-quantity="1" class="button product_type_variable add_to_cart_button add-to-cart-loop" data-product_sku=""  rel="nofollow"><span>Add to Cart</span></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End -->
            <!-- <div class="col-lg-3 col-md-3 col-sm-12 col-12" *ngFor="let product of all_products;let o=index;">
            <div class="main-fither mb-3" *ngIf="(o+1) <=8">
            <a href="/product/{{ product.id }}">
            <div class="fecherpro">
            <div class="agve-img">
            <img src="{{ product.images[0].src }}" class="w-100">
        </div>
        <div class="chip">
        <p [innerHTML]="product.name | removebr"></p>
        <span>{{ product.categories[0].name }}</span>
        <h4>${{ product.price }}</h4>
    </div>
</div>
</a>
</div>
</div> -->
</div>

<div class="row mt-5" *ngIf="!available_all_products">
    <div class="col-12 text-center">
        <h4>No products are available.</h4>
    </div>
</div>

</div>
</section>

<section class="our-blog">
    <div class="container">
        <div  class="contant-pagtitle">
            <h2>Our Blog</h2>
            <img src="assets/images/title-img.png">
            <p> Candide Information on the Industry.</p>
        </div>
        <div class="row myblogsowl" *ngIf="available_posts">
            <owl-carousel-o [options]="BlogOptions">
                <ng-template carouselSlide *ngFor="let b of all_posts">
                    <a href="/blogs/{{ b.id }}" class="text-decoration-none text-dark">
                        <div class="blog-text">
                            <h4 class="blog-date">{{ b.date | date : 'd' }}<br> <span class="text-uppercase">{{ b.date | date : 'MMM' }}</span></h4>
                            <div class="blog-zoom-section">
                                <img src="{{ b._embedded['wp:featuredmedia'][0]['source_url'] }}" class="w-100">
                            </div>
                            <div class="post-image-mask">
                                <span></span>
                            </div>
                            <h5>{{ b._embedded['wp:term'][0][1]['name'] }}</h5>
                            <h3>{{ b.title.rendered }}</h3>
                            <div class="blog-comment-img">
                                <p>Posted By<img src="assets/images/img-icon1.png" class="w-auto"> Admin <i class="far fa-comment-alt"></i> <i class="fas fa-share-alt"></i></p>
                            </div>
                            <div [innerHTML]="b.excerpt.rendered" class="blg_desc"></div>
                            <a href="/blogs/{{ b.id }}">Continue Reading</a>

                        </div>
                    </a>
                </ng-template>





            </owl-carousel-o>
        </div>
        <div class="row" *ngIf="!available_posts">
            <div class="col-12 my-5 text-center">
                <h4>No blogs are available.</h4>
            </div>
        </div>
    </div>

</section>

<!-- <section class="proud">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="honi-bottul-image-left">
                    <img src="assets/images/hony-left-image.png">
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 align-self-center">
                <div class="punch">
                    <h2>Proud & Punch Premium <br>
                        Natural Honey</h2>
                        <p>A latin professor at Hampden-Sydney College in Virginia, looked up one of the more <br>
                            obscure latin words, consectetur, from a lorem ipsum passage, and going through the.</p>
                        </div>


                        <div class="main-ingr">
                            <div class="ingr">
                                <h3>2</h3>
                                <p>Ingredients<br>
                                    <span>Lorem Ipsum is simply dummy</span></p>
                                </div>
                                <div class="ingr">
                                    <h3>460</h3>
                                    <p>PACKAGE WEIGHT<br>
                                        <span>Lorem Ipsum is simply dummy</span></p>
                                    </div>

                                    <div class="ingr">
                                        <h3>135</h3>
                                        <p>CALORIES ONLY<br>

                                            <span>Lorem Ipsum is simply dummy</span></p>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </section> -->

                    <section class="company-bg">
                        <!-- <section class="company">
                            <div class="container">
                                <div  class="contant-pagtitle">
                                    <h2>Company Core Product Profiles</h2>
                                    <img src="assets/images/title-img.png">
                                    <p>"What you are loving this week"</p>
                                </div>
                                <div class="maincore-pro core_prod" *ngIf="available_company_core_products">
                                    <owl-carousel-o [options]="CoreProductsOptions" class="Mainbanner">
                                        <ng-template carouselSlide *ngFor="let core of company_core_products">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4 col-sm-12 col-12 align-self-center">
                                                    <ng-container *ngFor="let core1 of core.left_side_products;let c=index;">

                                                        <div class="main-over">
                                                            <div class="over-text">
                                                                <a href="/product/{{ core1.ID }}" class="text-decoration-none">
                                                                    <h4>{{ core1.post_title }}</h4>
                                                                    <span><p>{{ core1.post_content }}</p></span>
                                                                </a>
                                                            </div>

                                                            <div class="over-image d-flex align-items-center">
                                                                <img src="{{ core1.image }}" class="w-100" (click)="change_center_image(core.cat_id,core1.image)">
                                                            </div>
                                                        </div>


                                                    </ng-container>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                                                    <div class="company-image">
                                                        <img src="{{ core.center_image }}" class="w-100">
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-12 col-12 align-self-center">
                                                    <ng-container *ngFor="let core2 of core.right_side_products;">

                                                        <div class="main-over">
                                                            <div class="over-image right-img d-flex align-items-center">
                                                                <img src="{{ core2.image }}" class="w-100" (click)="change_center_image(core.cat_id,core2.image)">
                                                            </div>
                                                            <div class="over-text right-text">
                                                                <a href="/product/{{ core2.ID }}" class="text-decoration-none">
                                                                    <h4>{{ core2.post_title }}</h4>
                                                                    <span><p>{{ core2.post_content }}</p></span>
                                                                </a>
                                                            </div>
                                                        </div>

                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-template>

                                    </owl-carousel-o>

                                </div>
                            </div>

                        </section> -->


                        <section class="testimonial mt-0">
                            <div class="container">

                                <div  class="contant-pagtitle">
                                    <h2>Testimonials</h2>
                                    <img src="assets/images/title-img.png">
                                    <p>Read what people are saying about our products.</p>
                                </div>
                                <div class="main-testimonial" *ngIf="available_testimonials">
                                    <owl-carousel-o [options]="TestimonialsOptions">
                                        <ng-template carouselSlide *ngFor="let t of all_testimonials">
                                            <div class="testimonial-text">
                                                <img src="{{ t.test_image_url }}" class="w-25">
                                                <div class="testi_desc" [innerHTML]="t.content.rendered"></div>
                                                <h4>{{ t.title.rendered }} <span>- Google Inc.</span></h4>
                                            </div>
                                        </ng-template>

                                        <!-- <ng-template carouselSlide>
                                        <div class="testimonial-text">
                                        <img src="http://fsd.opaldemo.com/admin/uploads/testimonials/shualena_robertson.jpg" class="w-25">
                                        <p>I went to a live show for Hillbilly Horror Stories last month and was surprised to receive a bottle of your sauce when I bought a T-shirt. <br>What a surprise it was. It’s super yummy!!!!! It’s like a fiesta in your mouth with every bite!!!! AMAZING!!!!</p>
                                        <h4>Shualena Robertson <span>- Google Inc.</span></h4>
                                    </div>
                                </ng-template>

                                <ng-template carouselSlide>
                                <div class="testimonial-text">
                                <img src="http://fsd.opaldemo.com/admin/uploads/testimonials/breck_gonzales.jpg" class="w-25">
                                <p>If you want only the best in your mouth, El Yucateco is the only way to go. With the variety of flavors, there's definitely one for everyone! I went black and never went back.
                            </p>
                            <h4>Breck Gonzales <span>- Google Inc.</span></h4>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                    <div class="testimonial-text">
                    <img src="http://fsd.opaldemo.com/admin/uploads/testimonials/david_noble.jpg" class="w-25">
                    <p>This is the best hot sauce with the best flavor hands down!
                </p>
                <h4>David Noble <span>- Google Inc.</span></h4>
            </div>
        </ng-template>

        <ng-template carouselSlide>
        <div class="testimonial-text">
        <img src="http://fsd.opaldemo.com/admin/uploads/testimonials/Zach_Washack.jpg" class="w-25">
        <p>Every single sauce is amazing. A personal favorite for at least 10 years.</p>
        <h4>Zach Washack <span>- Google Inc.</span></h4>
    </div>
</ng-template> -->

</owl-carousel-o>
</div>



</div>
</section>

</section>

<section class="retail-top">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                <h3 class="new-pro-title">NEW PRODUCTS</h3>
                <ng-container *ngIf="available_new_products">
                    <div class="new-pro" *ngFor="let new_prod of new_products;let odd=odd" [class.torto]="odd">
                        <a href="/product/{{ new_prod.id }}" class="text-decoration-none d-flex w-100">
                            <div class="new-image-pro">
                                <img src="{{ new_prod.images[0].src }}" width="50" *ngIf="new_prod.images.length > 0">
                                <img src="../assets/images/no-image.png" width="50" *ngIf="new_prod.images.length == 0">
                            </div>
                            <div class="new-image-text">
                                <p>{{ new_prod.name | removebr }}<br><span>${{ new_prod.price }}</span></p>
                            </div>
                        </a>

                    </div>
                </ng-container>

            </div>



            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                <h3 class="new-pro-title">Latest Products</h3>

                <ng-container *ngIf="available_latest_products">
                    <div class="new-pro" *ngFor="let new_prod of latest_product;let odd=odd" [class.torto]="odd">
                        <a href="/product/{{ new_prod.id }}" class="text-decoration-none d-flex w-100">
                            <div class="new-image-pro">
                                <img src="{{ new_prod.images[0].src }}" width="50" *ngIf="new_prod.images.length > 0">
                                <img src="../assets/images/no-image.png" width="50" *ngIf="new_prod.images.length == 0">
                            </div>
                            <div class="new-image-text">
                                <p>{{ new_prod.name | removebr }}<br><span>${{ new_prod.price }}</span></p>
                            </div>
                        </a>
                    </div>
                </ng-container>






            </div>


            <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt-sm-0 mt-4">
                <h3 class="new-pro-title mt-tab-2">Latest Twitter</h3>
                <div class="new-pro">
                    <div class="new-image-pro">
                        <img src="assets/images/simpaly.png">
                    </div>
                    <div class="new-image-text change">
                        <p>1 CHANGE + 1 HASHTAG = 1 <br>
                            MEAL ️ We want cooking to <br>
                            inspire personal change – and <br>
                            social good. For each use of…<br>
                            https://t.co/6vyWH3hoUG<br>
                            <span>2 months ago</span></p>
                        </div>
                    </div>


                    <div class="new-pro torto  month">
                        <div class="new-image-pro">
                            <img src="assets/images/simpaly.png">
                        </div>
                        <div class="new-image-text change">
                            <p>We loved hearing all that you <br>
                                had to say about your <br>
                                #OrganicMoments! Keep <br>
                                <span>2 months ago</span></p>
                            </div>
                        </div>


                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt-sm-0 mt-4">
                        <div class="insta image">
                            <h3 class="new-pro-title mt-tab-2">INSTAGRAM</h3>
                            <img src="assets/images/instagram-image.png" class="w-100">
                        </div>
                    </div>

                </div>
            </div>
        </section>



        <section class="retail">
            <div class="container px-mobile-0">
                <div class="retail-bg">
                    <div class="row mx-mobile-0">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 px-mobile-1">
                            <div class="retail-text">
                                <h3>RETAIL & FOODSERVICE PARTNERSHIP</h3>
                                <p>Consumer behavior in today’s food retail and grocery market is evolving rapidly. The change is real and happening each year as consumers prefer smaller
                                    retail stores, limited assortments of items, and a smaller store format. They also focus on local products and clean & healthy products and will not mind
                                    paying more money. With this concept in mind, we must scrutinize product profile and presentation to adapt to today’s changing behavior...</p>
                                    <a href="/page/retail-food-service-partnership">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section class="retail-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-12 col-12 pe-0 px-mobile-0">
                            <div class="private-lab">
                                <h4>PRIVATE LABEL SOLUTIONS</h4>
                                <p>Over the past few years, consumer<br>
                                    attitudes have changed to adapt to the <br>
                                    idea that “No Name Brand” or “Store <br>
                                    Brand” is equal in quality to the...</p>
                                    <a href="/page/private-label-solutions">Read More</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 col-12 px-0">
                                <div class="private-lab strat">
                                    <h4>STRATEGIC BRAND BUILDING &
                                        DEVELOPMENTS</h4>
                                        <p>Now that you have your brand developed,<br>
                                            your Company product positioning in the<br>
                                            market is another challenge.</p>
                                            <a href="/page/strategic-brand-building-developments">Read More</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-12 col-12 px-0">
                                        <div class="private-lab sorsing">
                                            <h4>SOURCING PARTNERSHIP</h4>
                                            <p>The idea of product sourcing is critical <br>
                                                in the success of the item. We at FSD <br>
                                                International refer to it as “Benchmarking,” <br>
                                                meaning that you send... </p>
                                                <a href="/page/sourcing-partnership">Read More</a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-12 col-12 ps-0 px-mobile-0">
                                            <div class="private-lab distribut">
                                                <h4>DISTRIBUTION SOLUTIONS & <br>
                                                    SERVICES</h4>
                                                    <p>Distribution, Logistics, and product <br>
                                                        availability are critical parts of your <br>
                                                        product development...</p>
                                                        <a href="/page/distribution-solutions-services">Read More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </section>
                                    <app-footer></app-footer>
