import { Component, OnInit,ViewChild,ElementRef,Input } from '@angular/core';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from '../api.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions,NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../product';
import { CartService } from 'src/app/cart.service';
@Component({
    selector: 'app-product-quick-view',
    templateUrl: './product-quick-view.component.html',
    styleUrls: ['./product-quick-view.component.css']
})
export class ProductQuickViewComponent implements OnInit {

    @Input() public productpassinfo;
    public cart_product_attr: Product;
    product_id : any;
    product_qty : any = 1;
    product_detail : any;
    product_images : any;
    available_product_images : boolean = false;
    available_product_detail :boolean = false;
    slug_product_name : any;
    product_regular_price : any;
    product_sale_price : any;
    brand_varient_info : any;
    all_variations : any;
    activated_varient_lable : any;

    activated_varient_id : any;
    activated_varient_regular_price : any;
    activated_varient_sale_price : any;

    isCustomerLogin : boolean = false;
    CustomerId : any;

    ProductImageOptions: OwlOptions = {
        loop: true,
        autoplay: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 1000,
        autoplaySpeed: 3000,
        autoplayHoverPause:true,
        navText:['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 1
            }
        },
        nav: true
    }
    /* end */
    no_image : any;

    constructor(
        public api : ApiService,
        private activemodal: NgbActiveModal,
        public cart : CartService
    ) {

        if(localStorage.getItem('customerinfo')){
            let customer_data = JSON.parse(localStorage.getItem('customerinfo'));
            this.CustomerId = customer_data.customer_id;
            this.isCustomerLogin = true;
        }else{
            this.isCustomerLogin = false;
        }

    }

    ngOnInit(): void {
        this.product_id = this.productpassinfo.product_id;
        this.getProductDetail();

    }

    getProductDetail(){
        this.api.getProductDetail(this.product_id).subscribe(
            (data:any)=>{
                this.product_detail = data;
                this.slug_product_name = this.product_detail.name.replace("<br>", "");
                if(this.product_detail.images.length > 0){
                    this.product_images = this.product_detail.images;
                    console.log(this.product_images );
                    this.available_product_images = true;
                }else{
                    this.no_image = '../assets/images/no-image.png';
                }



                this.product_regular_price = this.product_detail.regular_price;
                this.product_sale_price = this.product_detail.price;


                this.brand_varient_info = this.product_detail.attributes.filter(x=>x.id == 3); // get brand info

                // here we fetch the variations details
                if(this.product_detail.variations.length > 0){
                    var variations_detail = [];
                    for(let v=0;v<this.product_detail.variations.length;v++){
                        let variation_id = this.product_detail.variations[v];
                        this.api.getvariationdetail(this.product_id,variation_id).subscribe(
                            (data:any)=>{
                                variations_detail.push(
                                    {
                                        attributes : data.attributes,
                                        id : data.id,
                                        image : data.image,
                                        regular_price : data.regular_price,
                                        sale_price : data.sale_price,
                                        price : data.price,
                                    }
                                );
                            },(errors:any)=>{

                            }
                        );

                    }
                    this.all_variations = variations_detail;


                }

                // end
                this.available_product_detail = true;

            },(errors:any)=>{
                this.available_product_detail = false;
            }
        );
    }

    select_variation(attribute_id,attribute_label){
        var selected_attr;
        if(this.all_variations.length > 0){
            for(let y=0;y<this.all_variations.length;y++){
                let attr_data = this.all_variations[y].attributes;
                let filter_attr = attr_data.filter(x => (x.id == attribute_id && x.option == attribute_label) );
                if(filter_attr.length > 0){
                    selected_attr = this.all_variations[y];

                    this.activated_varient_lable = selected_attr.attributes[0].option;
                    this.activated_varient_id = selected_attr.id;
                    this.activated_varient_regular_price = selected_attr.regular_price;
                    this.activated_varient_sale_price = selected_attr.sale_price;

                    this.product_regular_price = selected_attr.regular_price;
                    this.product_sale_price = selected_attr.price;

                }
            }
        }
    }

    addtowishlist(){
        if(!this.isCustomerLogin){
            this.api.showError("Please login.");
        }else{

            let data = { user_id : this.CustomerId,product_id : this.product_id };
            this.api.addproducttowishlist(data).subscribe(
                (data:any)=>{
                    if(data.response.status == 200){
                        this.api.showSuccess(data.response.data.message);
                    }else{
                        this.api.showError(data.response.data.message);
                    }

                },(errors:any)=>{
                    console.log(errors);
                }
            );
        }

    }

    Addtocart(){
        this.cart_product_attr = new Product();
        this.cart_product_attr.product_id = this.product_id;
        this.cart_product_attr.product_name = this.slug_product_name;
        this.cart_product_attr.product_qty = this.product_qty;
        this.cart_product_attr.product_image = this.product_detail.images[0].src;
        this.cart_product_attr.product_price = this.product_sale_price;
        this.cart_product_attr.variation_id = this.activated_varient_id;
        this.cart_product_attr.product_subtotal =  (this.product_detail.price * this.product_qty);
        this.cart.addProduct(this.cart_product_attr);
        this.api.showSuccess("Product is successfully added to your cart");
    }

    close(){
        this.activemodal.close();
    }

    quntity(type){
        let qty = this.product_qty;
        if(type == '0'){
            if(qty != 1){
                qty--;
            }
        }else{
            qty++;

        }
        this.product_qty = qty;
    }

}
