<section class="footer">
	<div class="container">
		<div class="row">
			<div class="col-lg-3 col-md-3 col-sm-12 col-12">
				<div class="footer-logo">
					<a href="/"><img src="assets/images/footer-logo.png"></a>
				</div>
				<div class="footer-con">
					<p>We are an American based company, but <br>
						our activities are structured to operate <br>
						globally in all the markets we are present.</p>

						<div class="add-footer">
							<img src="assets/images/address-icon.png">
							<p>Atlanta, GA, USA</p>
						</div>
						<div class="call-footer">
							<img src="assets/images/call-icon.png"><a href="tel:404-595-1128">404-595-1128</a><br>
							<img src="assets/images/call-icon.png"><a href="tel:404-595-1131">404-595-1131</a>
						</div>
						<div class="email-footer">
							<img src="assets/images/email-icon.png"><a href="mailto:info@FSDIL.com">info&#64;FSDIL.com </a>
						</div>
						<div class="email-footer">
							<img src="assets/images/email-icon.png"><a href="mailto:sales@FSDIL.com">sales&#64;FSDIL.com</a>
						</div>
					</div>

				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-12">
					<h4 class="footer-title res">RECENT POSTS</h4>

					<ng-container *ngIf="available_posts">
						<div class="main-blog" *ngFor="let p of all_posts;let odd = odd;" [class.cen-bl]="odd">
							<a href="/blogs/{{ p.id }}" class="d-flex text-decoration-none">
								<div class="blog-img">
									<img src="{{ p._embedded['wp:featuredmedia'][0].source_url }}" width="130">
								</div>
								<div class="blog-text">
									<p>{{ p.title.rendered }}<br>
										<span>{{ p.date | date:'mediumDate' }}</span>
									</p>
								</div>
							</a>
						</div>
					</ng-container>

				</div>
				<div class="col-lg-2 col-md-2 col-sm-6 col-6">
					<h4 class="footer-title">ABOUT US</h4>
					<div class="footer-menu-list">
						<ul>
							<li><a href="/page/company-overview">Company Overview</a></li>
							<li><a href="/page/company-information">Company Information</a></li>
							<li><a href="/page/company-policy">Company Policy</a></li>
							<li><a href="/page/privacy-policy">Privacy Policy</a></li>
							<li><a href="/page/terms-of-use">Terms of Use</a></li>
							<li><a href="/page/return-policy">Return Policy</a></li>
							<li><a href="/contact">Contact Us</a></li>
							<li><a href="/page/services"><span class="capitalize">Services</span></a></li>
							<li><a href="/page/our-locations"><span class="capitalize">Our Locations</span></a></li>
						</ul>
					</div>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-6 col-6">
					<h4 class="footer-title">Our Brands</h4>
					<div class="footer-menu-list">
						<ul *ngIf="available_attribute">
							<li *ngFor="let r of brand_attributes" ><a href="/shop?pa_brand={{ r.id }}&type=brand">{{ r.name }}</a></li>
							<li><a href="/partner-brands"><span>Partner Brands</span></a></li>
						</ul>
					</div>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-12 col-12">
					<div class="footer-menu-list useful-links">
						<h4 class="footer-title">	Useful links</h4>
						<ul>
							<li><a href="/squarebrand-registration"><b class="squar">Squarebrand</b> Registration</a></li>
							<li><a href="/flipzin-sign-up"><b class="sign">FLIPZIN Newsletter</b> Sign-up</a></li>
							<li><a href="/page/faq">FAQ</a></li>
							<li><a href="/news-room">News Room</a></li>
							<li><a href="/careers">Careers</a></li>
							<li><a href="/page/download"><span>Download</span></a></li>
							<li><a href="/recipe"><span>Recipe</span></a></li>
							<li><a href="/page/gallery"><span>Gallery</span></a></li>
							<li><a href="/page/links">Links</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="copyright">
		<div class="container">
			<div class="row">
				<div class="col-lg-3 col-md-5 col-sm-12 col-12">
					<p>FSDIL © 2022 POWERED BY FSD International</p>
				</div>
				<div class="col-lg-6 col-md-4 col-sm-12 col-12">
					<div class="main-social">
						<div class="follow-title">
							<p>Follow Us: </p>
						</div>
						<div class="social">
							<a href="https://www.facebook.com/FSDIntl/" target="_blank">
								<img src="assets/images/facebook.png">
							</a>

							<a href="https://twitter.com/fsdintl" target="_blank">
								<img src="assets/images/twitter.png">
							</a>

							<a href="https://www.instagram.com/fsdintl/" target="_blank">
								<img src="assets/images/instagram.png">
							</a>

						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-12">
					<img src="assets/images/footer-visa.png" class="w-100">

				</div>
			</div>
		</div>

	</section>

	<div class="right-social">
		<ul>
			<li>
				<a  target="_blank" href="https://www.facebook.com/fsdintl"><img  src="assets/images/fb.jpeg"></a>
			</li>
			<li>
				<a  target="_blank" href="https://twitter.com/fsdintl"><img  src="assets/images/tw.jpeg"></a>
			</li>
			<li>
				<a  target="_blank" href="https://www.instagram.com/fsdintl/"><img  src="assets/images/insta.jpeg"></a>
			</li>
		</ul>
	</div>
