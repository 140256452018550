import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { InnerheaderComponent } from '../innerheader/innerheader.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [];

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        InnerheaderComponent,


    ],
    imports: [
        [RouterModule.forChild(routes)],
        CommonModule,
        SharedRoutingModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        InnerheaderComponent,
        
    ]
})
export class SharedModule { }
