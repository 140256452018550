import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';


const routes: Routes = [
    {
        path:'',
        component:HomeComponent
    },

    {
        path: 'chipotlepepper',
        loadChildren: () => import('./chipotlepepper/chipotlepepper.module').then(m => m.ChipotlepepperModule)
    },

    {
        path: 'shop',
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
    },

    {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
    },

    {
        path: 'termscondition',
        loadChildren: () => import('./termscondition/termscondition.module').then(m => m.TermsconditionModule)
    },

    {
        path: 'privacypolicy',
        loadChildren: () => import('./privacypolicy/privacypolicy.module').then(m => m.PrivacypolicyModule)
    },

    {
        path: 'product',
        loadChildren: () => import('./product-detail/product-detail.module').then(m => m.ProductDetailModule)
    },

    {
        path: 'customer',
        loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)
    },

    {
        path: 'page',
        loadChildren: () => import('./page/page.module').then(m => m.PageModule)
    },
    {
        path: 'news-room',
        loadChildren: () => import('./news/news.module').then(m => m.NewsModule)
    },
    {
        path: 'recipe',
        loadChildren: () => import('./recipe/recipe.module').then(m => m.RecipeModule)
    },

    {
        path: 'cart',
        loadChildren: () => import('./cart/cart.module').then(m => m.CartModule)
    },


    {
        path: 'checkout',
        loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)
    },


    {
        path: 'blogs',
        loadChildren: () => import('./blogs/blogs.module').then(m => m.BlogsModule)
    },

    {
        path: 'resetpassword',
        loadChildren: () => import('./rest-password/rest-password.module').then(m => m.RestPasswordModule)
    },

    {
        path: 'category-products',
        loadChildren: () => import('./category-products/category-products.module').then(m => m.CategoryProductsModule)
    },
    {
        path : 'partner-brands',
        loadChildren: () => import('./partner-brands/partner-brands.module').then(m => m.PartnerBrandsModule)
    },
    {
        path : 'squarebrand-registration',
        loadChildren: () => import('./squarebrand-registration/squarebrand-registration.module').then(m => m.SquarebrandRegistrationModule)
    },
    {
        path : 'careers',
        loadChildren: () => import('./careers/careers.module').then(m => m.CareersModule)
    },
    {
        path : 'retail-partner',
        loadChildren: () => import('./retail-partner/retail-partner.module').then(m => m.RetailPartnerModule)
    },
    {
        path : 'partnership-form',
        loadChildren: () => import('./partnership/partnership.module').then(m => m.PartnershipModule)
    },
    {
        path : 'flipzin-sign-up',
        loadChildren: () => import('./flipzin/flipzin.module').then(m => m.FlipzinModule)
    },
    {
        path : 'distributor-registration',
        loadChildren: () => import('./distributor/distributor.module').then(m => m.DistributorModule)
    },
    {path: 'search',loadChildren: () => import('./search/search.module').then(m => m.SearchModule)},
    



    

];



@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
