import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute,Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ProductQuickViewComponent } from '../product-quick-view/product-quick-view.component';
import { Product } from '../product';
import { CartService } from 'src/app/cart.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    available_category : boolean = false;
    all_category : any;
    active_category_id : any;

    available_fsd_products : boolean = false;
    available_all_products :boolean = false;

    our_products : any;
    all_products : any;
    fsd_fetured_products : any;

    available_posts : boolean = false;
    all_posts : any;

    available_new_products: boolean = false;
    new_products : any;

    available_latest_products: boolean = false;
    latest_product : any;

    available_premium_products: boolean = false;
    premium_products : any;

    available_company_core_products: boolean = false;
    company_core_products : any;
    active_company_core_image : any;
    isCustomerLogin : boolean = false;
    CustomerId : any;
    quick_view_product_id : any;
    /* Testimonials owl */
    all_testimonials :any;
    available_testimonials :boolean = false;



public cart_product_attr: Product;

    activated_varient_lable : any;
    activated_varient_id : any = 0;
    activated_varient_regular_price : any;
    slug_product_name : any;
    product_qty : any = 1;
    product_sale_price : any;
    activated_varient_sale_price : any;



    TestimonialsOptions: OwlOptions = {
        loop: true,
        autoplay: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 1000,
        autoplaySpeed: 3000,
        autoplayHoverPause:true,
        navText:['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 3
            },
            740: {
                items: 3
            },
            940: {
                items: 3
            }
        },
        nav: true
    }
    /* end */

    /* Blog Owl */
    BlogOptions: OwlOptions = {
        loop: true,
        autoplay: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 1000,
        autoplaySpeed: 3000,
        autoplayHoverPause:true,
        navText:['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 3
            },
            740: {
                items: 3
            },
            940: {
                items: 3
            }
        },
        nav: true
    }
    /* End */

    /* Banner Owl */
    MainBannerOptions: OwlOptions = {
        loop: true,
        autoplay: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 1000,
        autoplaySpeed: 3000,
        navText:['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 1
            }
        },
        nav: true
    }
    /* End */

    /* Company Core Products */
    CoreProductsOptions: OwlOptions = {
        loop: true,
        autoplay: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 1000,
        autoplaySpeed: 3000,
        autoplayHoverPause:true,
        navText:['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 1
            }
        },
        nav: true
    }
    /* End */

    /* Primium Owl */
    PremiumOptions: OwlOptions = {
        loop: true,
        autoplay: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 1000,
        autoplaySpeed: 3000,
        autoplayHoverPause:true,
        navText:['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 1
            }
        },
        nav: true
    }
    /* End */

    /* Company Core Products owl */
    CompanyCoreOptions: OwlOptions = {
        loop: true,
        autoplay: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 1000,
        autoplaySpeed: 3000,
        navText:['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 1
            }
        },
        nav: true
    }
    /* End  */


    constructor(
        public api : ApiService,
        public route : ActivatedRoute,
        public router : Router,
        public cart : CartService,
        private modalService: NgbModal,
    ) {

        if(localStorage.getItem('customerinfo')){
            let customer_data = JSON.parse(localStorage.getItem('customerinfo'));
            this.CustomerId = customer_data.customer_id;
            this.isCustomerLogin = true;
        }else{
            this.isCustomerLogin = false;
        }
    }

    ngOnInit(): void {
        this.getallcategory();
        this.getproducts();
        this.getfeturedproducts();

        this.getallPosts();
        this.getpremiumproducts();

        this.gettestimonial();

        this.fetchcoreproducts();

    }

    getallcategory(){
        this.api.getAllCategories().subscribe(
            (data:any)=>{
                if(data.length > 0){
                    let allcategory = data;
                    // sort category assending orders
                    allcategory.sort(function(a, b) {
                        return a.menu_order - b.menu_order;
                    });
                    // end
                    allcategory = allcategory.filter(x=> (x.id != 43 && x.id != 45 && x.id != 49 && x.id != 55)); // remove premium category from data
                    this.all_category = allcategory;
                    this.active_category_id = this.all_category[0].id; // make first one is default active
                    this.available_category = true;
                }

            },(errors:any)=>{

            }
        )
    }

    getfeturedproducts(){
        // Get Fetured Products
        this.api.categoryproducts('49').subscribe(
            (data:any)=>{
                let fetured_product_ids = data;
                //let cat_products = data.splice(0,8);
                this.fsd_fetured_products = data;
                if(data.length > 0){
                    this.available_fsd_products = true;
                }else{
                    this.available_fsd_products = false;
                }
            },(errors:any)=>{

            }
        );
        // End
    }

    getproducts(){
        this.api.getAllHomeProducts().subscribe(
            (data:any)=>{
                /* Random products */
                let ids = data.map(x=> x.id);
                let random_ids = ids.sort(() => Math.random() - 0.5);
                let final_data = [];
                for(let m =0;m<random_ids.length;m++){
                    let p = data.filter(x=>x.id == random_ids[m])[0];
                    final_data.push(p);
                    if (m>6) {
                        break;
                    }
                }
                /* End */


                this.all_products = final_data;

                if(this.all_products.length > 0){
                    this.available_all_products = true;
                }else{
                    this.available_all_products = false;
                }
                this.getnewandlatestproducts(data);
            },(errors:any)=>{

            }
        )
    }







    gettestimonial(){
        this.api.getalltestimonial().subscribe(
            (data:any)=>{
                if(data.length > 0){
                    this.all_testimonials = data;
                    for(let s=0;s<data.length;s++){
                        this.api.getpagemedia(data[s].id).subscribe(
                            (data:any)=>{
                                this.all_testimonials[s]['test_image_url'] = data[0]['guid']['rendered']
                            }
                        );
                    }
                    this.available_testimonials = true;
                }else{
                    this.available_testimonials = false;
                }

            },(errors:any)=>{

            }
        );
    }

    change_category(id){

        this.router.navigate(['/category-products/'+id]);
    }

    getallPosts(){
        this.api.getallpost().subscribe(
            (data:any)=>{
                if(data.length > 0){
                    this.all_posts = data;
                    this.available_posts = true;
                }
            },(errors:any)=>{

            }
        );
    }

    getnewandlatestproducts(products){
        const shuffled = products.sort(() => 0.5 - Math.random());
        let selected = shuffled.slice(0, 3);

        this.new_products = selected;
        if(selected.length > 0){
            this.available_new_products = true;
        }else{
            this.available_new_products = false;
        }

        products.sort( function ( a, b ) { return b.id - a.id; } );
        let latest_product = products.slice(0,3);
        //
        this.latest_product = latest_product;
        if(latest_product.length > 0){
            this.available_latest_products = true;
        }else{
            this.available_latest_products = false;
        }
    }

    getpremiumproducts(){
        this.api.categoryproducts('43').subscribe(
            (data:any)=>{
                if(data.length > 0){
                    this.premium_products = data;

                    this.available_premium_products = true;
                }
            },(errors:any)=>{

            }
        );
    }

    fetchcoreproducts(){
        this.api.getcompanycoreproducts().subscribe(
            (data:any)=>{
                if(data.response.data.coreproducts.length > 0){
                    this.company_core_products = data.response.data.coreproducts;
                    this.available_company_core_products = true;
                }
            },(errors:any)=>{

            }
        );
    }

    change_center_image(cat_id,new_image){

        for(let m=0;m<this.company_core_products.length;m++){
            if(this.company_core_products[m].cat_id == cat_id){
                this.company_core_products[m].center_image = new_image;
            }
        }

    }




    addtowishlist(product_id){

        if(!this.isCustomerLogin){
            this.api.showError("Please login.");
        }else{

            let data = { user_id : this.CustomerId,product_id : product_id };
            this.api.addproducttowishlist(data).subscribe(
                (data:any)=>{
                    if(data.response.status == 200){
                        this.api.showSuccess(data.response.data.message);
                    }else{
                        this.api.showError(data.response.data.message);
                    }

                },(errors:any)=>{
                    console.log(errors);
                }
            );
        }

    }

    quickview(product_id){
        const modalRef = this.modalService.open(ProductQuickViewComponent, { size: 'lg' });
        modalRef.componentInstance.productpassinfo = { product_id : product_id };
    }
    Addtocart(product_id,name,img,price){
        // this.getProductDetail(product_id);
        this.slug_product_name = name.replace("<br>", "");

        this.cart_product_attr = new Product();
        this.cart_product_attr.product_id = product_id;
        this.cart_product_attr.product_name = this.slug_product_name;
        this.cart_product_attr.product_qty = 1;
        this.cart_product_attr.product_image = img.src;
        this.cart_product_attr.product_price = price;
        // this.cart_product_attr.variation_id = this.activated_varient_id;
        this.cart_product_attr.product_subtotal =  (price * 1);
        this.cart.addProduct(this.cart_product_attr);
        this.api.showSuccess("Product is successfully added to your cart");
    }

}
